import { MutationKey, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure } from '@playq/services-shared';
import { AppEntityContentResponse } from '@playq/octopus2-apps';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { appToolkit } from '/store';

import { retrieveConfigMutationKey } from './constants';

export const useConfigRetrieveSingleTime = (
  options?: UseMutationOptions<AppEntityContentResponse | undefined, GenericFailure | Error, number | undefined>
) => {
  const appID = useSelector(appToolkit.selectors.appID);
  const mutationKey: MutationKey = retrieveConfigMutationKey.concat(appID?.serialize());

  const res = useEitherMutation((version) => services2.appConfigService.retrieve(appID as AppID, version), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey,
    ...options,
  });

  return {
    ...res,
    mutationKey,
  };
};
