import { MutationKey } from '@tanstack/query-core';

import { AppConfigServiceClient } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';

export const appConfigService: AppConfigServiceClient = services2.appConfigService;
export const appConfigServiceName: string = appConfigService.getClassName();

export const retrieveConfigMutationKey: MutationKey = ['service', 'config', 'retrieve'];
